<template>
  <li class="flex flex-row justify-between min-w-64 px-1 py-1 items-center rounded-full bg-gray-200 text-center">
    <div class="flex gap-2">
      <v-btn
        height="32"
        :icon="!item.got ? 'mdi-check' : 'mdi-check-all'"
        variant="plain"
        width="32"
        @click="onCheckButtonClick"
      />
      <div class="flex items-center">
        <p :class="textClass">{{ item.name }}</p>
      </div>
    </div>
    <v-btn
      height="32"
      icon="mdi-trash-can-outline"
      variant="plain"
      width="32"
      @click="onDeleteButtonClick"
    />
  </li>
</template>

<script lang="ts">
import { ShoppingItem } from '@/models/ShoppingItem';

export default {
  props: {
    /* The shopping item that is displayed by this component */
    item: {
      required: true,
      type: ShoppingItem,
    },
  },
  computed: {
    /** The classes that should be used to style the text that displays this
     * shopping list item's name */
    textClass: function(): string {
      return this.item.got ? "line-through italic text-gray-500" : "";
    },
  },
  methods: {
    onCheckButtonClick: function(): void {
      this.item.got = !this.item.got;
      this.$emit("updateItem", this.item.created.toString());
      this.$emit("sortItems");
    },
    onDeleteButtonClick: function(): void {
      this.$emit("deleteItem", this.item.created.toString());
    }
  }
};
</script>