<template>
  <li
    class="flex flex-row gap-4 justify-between min-w-64 px-1 py-1 items-center rounded-full"
    :class="backgroundColor"
  >

    <!-- left side of todo item -->
    <div class="flex gap-2">

      <!-- check button -->
      <v-btn
        height="32"
        :icon="item.done ? 'mdi-checkbox-marked-outline' : item.recurAfter > 0 ? 'mdi-checkbox-blank-badge-outline' : 'mdi-checkbox-blank-outline'"
        variant="plain"
        width="32"
        @click="onCheckButtonClick"
      />

      <!-- to do item name  -->
      <div class="flex items-center">
        <p :class="textClass">{{ item.name }}</p>
      </div>

    </div>

    <!-- right side of todo item -->
    <div class="flex gap-2">

      <!-- due date display -->
      <div
        v-if="item.dueDate"
        class="flex items-center"
      >
        <p :class="textClass">{{ formatDueDate(item.dueDate) }}</p>
      </div>

      <!-- delete button -->
      <v-btn
        height="32"
        icon="mdi-trash-can-outline"
        variant="plain"
        width="32"
        @click="onDeleteButtonClick"
      />

    </div>

  </li>
</template>

<script lang="ts">
import { ToDoItem } from '@/models/ToDoItem';

export default {
  props: {
    /* The shopping item that is displayed by this component */
    item: {
      required: true,
      type: ToDoItem,
    },
  },
  computed: {
    /** Calculates the background color for this todo item, based on it's due
     * date. */
    backgroundColor: function (): string {
      if (this.item.dueDate && !this.item.done) {
        const msUntilDue = new Date(this.item.dueDate).getTime() - new Date().getTime();
        const daysUntilDue = Math.ceil(msUntilDue / (1000 * 60 * 60 * 24));
        if (daysUntilDue === 1) {
          return 'bg-yellow-200';
        } else if (daysUntilDue === 0) {
          return 'bg-orange-200';
        } else if (daysUntilDue < 0) {
          return 'bg-red-200';
        }
      }
      return 'bg-gray-200';
    },

    /** The classes that should be used to style the text that displays this
     * shopping list item's name */
    textClass: function (): string {
      return this.item.done ? "line-through italic text-gray-500" : "";
    },
  },
  methods: {
    onCheckButtonClick: function (): void {
      this.item.done = !this.item.done;
      this.$emit("updateItem", this.item.created.toString());
      this.$emit("sortItems");
    },
    onDeleteButtonClick: function (): void {
      this.$emit("deleteItem", this.item.created.toString());
    },
    /** Formats the due date for the todo list UI for this todo item */
    formatDueDate(dueDate: Date): string {
      const currentYear = new Date().getFullYear();
      const dueYear = dueDate.getFullYear();
      const month = (`${dueDate.getMonth() + 1}`).slice(-2);
      const day = (`${dueDate.getDate()}`).slice(-2);
      const yearSuffix = dueYear !== currentYear ? `/${dueYear % 100}` : '';
      return `${month}/${day}${yearSuffix}` as string;
    },

  }
};
</script>