import type { DanOrRegina } from "@/enums/DanOrRegina";
import { formatDateYYYYMMDD } from "@/utils/utils";

/** A single chore that Dan and/or Regina have to do, including a record of if
 * and when and who completed it */
export class Chore {

  /** A unique ID for this chore */
  id: string;

  /** A human-readable name for this chore */
  name: string;

  /** History of if and when this chore has been completed in the past (or
   * future) */
  history: ChoreDayRecord[];

  /** The order in which to sort this chore in the UI  */
  order: number;

  /** The name of the mdi-* icon to be used for this chore */
  icon: string;

  /** Creates a new chore with the given data */
  constructor(
    id: string,
    name: string,
    history: ChoreDayRecord[],
    order: number,
    icon: string,
  ) {
    this.id = id;
    this.name = name;
    this.history = history;
    this.order = order;
    this.icon = icon;
  }

  /**
   * Retrieves a record matching the given date, or null if no matching record is found.
   */
  getRecordForDate(date: Date): ChoreDayRecord | null {
    // convert date to YYYYMMDD string
    const dateString: string = formatDateYYYYMMDD(date);
    const matchingRecords: ChoreDayRecord[] = this.history.filter(record => record.date === dateString);
    if (matchingRecords.length > 0) {
      return matchingRecords[0];
    } else {
      return null;
    }
  }

}

/** Data about if and who completed a single chore on a single day */
export class ChoreDayRecord {

  /** The date (day) that this chore record corresponds to), as a string in the
   * format YYYYMMDD */
  date: string;

  /** The person who completed this chore on this day */
  completedBy: DanOrRegina | null;

  /**
   * Creates a new ChoreDayRecord with the given data.
   * @param date The date (day) that this chore record corresponds to), as a
   * string in the format YYYYMMDD
   * @param  completedBy The person who completed this chore on this day
   */
  constructor(date: string, completedBy: DanOrRegina | null) {

    // validate date input
    const regex = /^\d{4}\d{2}\d{2}$/;
    if (!regex.test(date)) {
      throw new Error(`Date string "${date}" must be in format YYYYMMDD`);
    }

    this.date = date;
    this.completedBy = completedBy;

  }

  /** Returns true only if this chore was completed on the day represented by
   * this record. */
  isCompleted(): boolean {
    return this.completedBy != null;
  }

}