import type { IResult } from '@/models/IResult';
import { FirebaseService } from './FirebaseService';
import { CollectionReference, Firestore, collection, type DocumentData, doc, DocumentReference, setDoc, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { Chore, type ChoreDayRecord } from '@/models/Chore';
import { ShoppingItem } from '@/models/ShoppingItem';
import { ToDoItem } from '@/models/ToDoItem';

//#region TODO

/** The name of the collection of 'todo' items in the Firestore database */
const TO_DO_COLLECTION_NAME: string = 'todo';

/**
 * Gets all todo items from the database.
 *
 * @return If successful, an array of todo item objects will be
 * returned. If unsuccessful, the Error property may contain more information.
 */
export async function getAllToDoItems(): Promise<IResult<ToDoItem[]>> {
  let result: IResult<ToDoItem[]>;
  try {
    const firestore: Firestore = FirebaseService.GetFirestore();
    const toDoItemCollectionRef: CollectionReference<DocumentData> = collection(firestore, TO_DO_COLLECTION_NAME);
    const toDoItemQuerySnapshot: QuerySnapshot<DocumentData> = await getDocs(toDoItemCollectionRef);

    const toDoItems: ToDoItem[] = [];
    for (let doc of toDoItemQuerySnapshot.docs) {
      const docData: DocumentData = doc.data();
      const toDoItem: ToDoItem = new ToDoItem(
        Number.parseFloat(docData.created),
        docData.name,
        docData.done,
        docData.dueDate ? docData.dueDate.toDate() : null,
        docData.recurAfter ? docData.recurAfter : null
      );
      toDoItems.push(toDoItem);
    }

    result = {
      success: true,
      value: toDoItems,
    };

  } catch (error) {
    console.error('Error getting all To Do List Items: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}

/**
 * Add or update a to do item in the Firestore database.
 *
 * @param toDoItem the todo item to be added or updated
 * @return a result object, containing information about the success or error of
 * this operation.
 */
export async function addOrUpdateToDoItem(toDoItem: ToDoItem): Promise<IResult<boolean>> {
  let result: IResult<boolean>;
  try {

    // get existing todo item, if any
    const firestore: Firestore = FirebaseService.GetFirestore();
    const toDoItemDocRef: DocumentReference<DocumentData> = doc(firestore, TO_DO_COLLECTION_NAME, toDoItem.created.toString());
    await setDoc(toDoItemDocRef, { ...toDoItem });

    result = {
      success: true,
    };

  } catch (error) {
    console.error('Error adding or updating to do Item: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}


/**
 * Delete a to do item from the Firestore database.
 *
 * @param id the id of to do item to be delete (same as it's created
 * property)
 * @return a result object, containing information about the success or error of
 * this operation.
 */
export async function deleteToDoItem(id: string): Promise<IResult<boolean>> {
  let result: IResult<boolean>;
  try {

    // get existing to do item
    const firestore: Firestore = FirebaseService.GetFirestore();
    const toDoItemDocRef: DocumentReference<DocumentData> = doc(firestore, TO_DO_COLLECTION_NAME, id);
    
    await deleteDoc(toDoItemDocRef);

    result = {
      success: true,
    };

  } catch (error) {
    console.error('Error deleting to do Item: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}


//#endregion TODO

//#region CHORES

/** The name of the collection of 'chore' items in the Firestore database */
const CHORE_COLLECTION_NAME: string = 'chores';


/**
 * Gets all chores from the database.
 *
 * @return If successful, an array of Chore objects will be returned.
 * If unsuccessful, the Error property may contain more information.
 */
export async function getAllChores(): Promise<IResult<Chore[]>> {
  let result: IResult<Chore[]>;
  try {
    const firestore: Firestore = FirebaseService.GetFirestore();
    const choreCollectionRef: CollectionReference<DocumentData> = collection(firestore, CHORE_COLLECTION_NAME);
    const choresQuerySnapshot: QuerySnapshot<DocumentData> = await getDocs(choreCollectionRef);

    const chores: Chore[] = [];
    for (let doc of choresQuerySnapshot.docs) {
      const docData: any = doc.data();
      const historyCollectionPath: string = `${CHORE_COLLECTION_NAME}/${docData.id}/history`;
      const historyCollectionRef: CollectionReference<DocumentData> = collection(firestore, historyCollectionPath);
      const historyQuerySnapshot: QuerySnapshot<DocumentData> = await getDocs(historyCollectionRef);
      const history: ChoreDayRecord[] = [];
      for (let historyDoc of historyQuerySnapshot.docs) {
        const historyDocData: any = historyDoc.data() as ChoreDayRecord;
        history.push(historyDocData);
      }
      const chore: Chore = new Chore(
        docData.id,
        docData.name,
        history,
        docData.order,
        docData.icon,
      );
      chores.push(chore);
    }

    chores.sort((a, b) => a.order - b.order);

    result = {
      success: true,
      value: chores,
    };

  } catch (error) {
    console.error('Error getting all Chores: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}


/**
 * Updates a chore with the given record in the database.
 *
 * @param  id The ID of the chore to update.
 * @param  record The updated record for a specific day to save.
 * @return  If successful, the updated Chore object will be returned. If
 * unsuccessful, the Error property may contain more information,.
 */
export async function updateChore(id: string, record: ChoreDayRecord): Promise<IResult<boolean>> {
  let result: IResult<boolean>;
  try {

    // get chore
    const firestore: Firestore = FirebaseService.GetFirestore();
    const choreDocRef: DocumentReference<DocumentData> = doc(firestore, CHORE_COLLECTION_NAME, id);
    const choreDocSnapshot: DocumentSnapshot<DocumentData> = await getDoc(choreDocRef);

    // return error if chore doesn't exist
    if (!choreDocSnapshot.exists()) {
      result = {
        success: false,
        error: `Error updating chore: Chore with id ${id} does not exist.`,
      };
    }

    // find existing record for chore for given day
    const fullHistoryDocPath: string = `${CHORE_COLLECTION_NAME}/${id}/history`;
    const historyDocRef = doc(firestore, fullHistoryDocPath, record.date);
    await setDoc(historyDocRef, { ...record });

    result = {
      success: true,
    };

  } catch (error) {
    console.error('Error updating Chore: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}

//#endregion CHORES

//#region SHOPPING ITEMS

/** The name of the collection of 'chore' items in the Firestore database */
const SHOPPING_ITEMS_COLLECTION_NAME: string = 'shopping-items';

/**
 * Gets all shopping items from the database.
 *
 * @return If successful, an array of shopping list item objects will be
 * returned. If unsuccessful, the Error property may contain more information.
 */
export async function getAllShoppingItems(): Promise<IResult<ShoppingItem[]>> {
  let result: IResult<ShoppingItem[]>;
  try {
    const firestore: Firestore = FirebaseService.GetFirestore();
    const shoppingItemCollectionRef: CollectionReference<DocumentData> = collection(firestore, SHOPPING_ITEMS_COLLECTION_NAME);
    const shoppingItemQuerySnapshot: QuerySnapshot<DocumentData> = await getDocs(shoppingItemCollectionRef);

    const shoppingItems: ShoppingItem[] = [];
    for (let doc of shoppingItemQuerySnapshot.docs) {
      const docData: DocumentData = doc.data();
      const shoppingItem: ShoppingItem = new ShoppingItem(
        Number.parseFloat(docData.created),
        docData.name,
        docData.got
      );
      shoppingItems.push(shoppingItem);
    }

    result = {
      success: true,
      value: shoppingItems,
    };

  } catch (error) {
    console.error('Error getting all Shopping List Items: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}

/**
 * Add or update a shopping item in the Firestore database.
 *
 * @param shoppingItem the shopping item to be added or updated
 * @return a result object, containing information about the success or error of
 * this operation.
 */
export async function addOrUpdateShoppingItem(shoppingItem: ShoppingItem): Promise<IResult<boolean>> {
  let result: IResult<boolean>;
  try {

    // get existing shopping item, if any
    const firestore: Firestore = FirebaseService.GetFirestore();
    const shoppingItemDocRef: DocumentReference<DocumentData> = doc(firestore, SHOPPING_ITEMS_COLLECTION_NAME, shoppingItem.created.toString());
    await setDoc(shoppingItemDocRef, { ...shoppingItem });

    result = {
      success: true,
    };

  } catch (error) {
    console.error('Error adding or updating Shopping Item: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}


/**
 * Delete a shopping item from the Firestore database.
 *
 * @param id the id of shopping item to be delete (same as it's created
 * property)
 * @return a result object, containing information about the success or error of
 * this operation.
 */
export async function deleteShoppingItem(id: string): Promise<IResult<boolean>> {
  let result: IResult<boolean>;
  try {

    // get existing shopping item
    const firestore: Firestore = FirebaseService.GetFirestore();
    const shoppingItemDocRef: DocumentReference<DocumentData> = doc(firestore, SHOPPING_ITEMS_COLLECTION_NAME, id);
    
    await deleteDoc(shoppingItemDocRef);

    result = {
      success: true,
    };

  } catch (error) {
    console.error('Error deleting Shopping Item: ', error);
    result = {
      success: false,
      error: `${error}`,
    };
  }
  return result;
}

//#endregion SHOPPING ITEMS
