
/**
 * Returns an array of dates for a given number of days before and after today.
 *
 * @param {number} daysBeforeToday - The number of days before today.
 * @param {number} daysAfterToday - The number of days after today.
 * @return {Date[]} An array of dates.
 */
export function getDatesBeforeAndAfterToday(daysBeforeToday: number, daysAfterToday: number) {
  const startDate = new Date(Date.now() - daysBeforeToday * 24 * 60 * 60 * 1000);
  return Array.from({ length: daysBeforeToday + daysAfterToday + 1 }, (_, i) => {
    return new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
  });
};

/**
 * Checks if the given date is today.
 * @param date The date to be checked.
 * @return Returns true if the given date is today, false otherwise.
 */
export function isToday(date: Date): boolean {
  const now = new Date();
  const isToday: boolean =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();
  return isToday;
}

/**
 * Formats a date object into a string in the 'YYYYMMDD' format.
 */
export function formatDateYYYYMMDD(date: Date): string {
  return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
}

/**
 * Converts a string in the format 'YYYYMMDD' to a JS Date object.
 */
export function parseDateYYYYMMDD(dateString: string): Date {
  const year = Number.parseInt(dateString.slice(0, 4));
  const month = Number.parseInt(dateString.slice(4, 6)) - 1;
  const day = Number.parseInt(dateString.slice(6));
  return new Date(year, month, day);
}

/**
 * Calculates the number of days between the given date and today. Ignores
 * hours, minutes, seconds, and milliseconds..
 * @param date  The date to calculate the number of days until or since.
 * @return The number of days until (positive) or since (negative) the given
 * date. Returns 0 if the given date is today.
 */
export function daysUntilOrSince(date: Date): number {
  const msPerDay = 24 * 60 * 60 * 1000;
  const dateAdjusted = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const todayAdjusted = new Date(Date.now());
  todayAdjusted.setHours(0, 0, 0, 0);
  const diff = dateAdjusted.getTime() - todayAdjusted.getTime();
  return Math.round(diff / msPerDay);
}

