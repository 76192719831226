<template>
  <div class="flex justify-center items-baseline mb-8 mt-16 gap-4">
    <v-icon
      v-if="icon"
      :icon="icon"
    />
    <h2 class="text-3xl text-center">{{ title }}</h2>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    /** The made of the material design icon icon that should be displayed in
     * this section header. Must contain mdi prefix and matchin an icon from
     * link on Vuetify's icon docs page. */
    icon: {
      required: false,
      type: String,
    },
    /** The title to be displayed for this section header */
    title: {
      required: true,
      type: String
    },
  },
}
</script>