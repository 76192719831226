<script setup lang="ts">
import { GoogleAuthProvider, getRedirectResult, signInWithPopup, signInWithRedirect, type User, type UserCredential } from 'firebase/auth';
import ChoreGrid from './components/ChoreGrid.vue';
import { FirebaseService } from './services/FirebaseService';
</script>

<template>

  <header>
    <div class="flex flex-row justify-center items-center gap-4 my-12">
      <v-icon
        v-if="includeTony"
        class=""
        icon="mdi-cat"
        size="48"
        color="orange"
      />
      <v-icon
        class=""
        icon="mdi-cat"
        size="48"
        color="green"
      />
      <h1 class="text-5xl bold">The Kat App</h1>
      <v-icon
        v-if="!includeTony"
        class=""
        icon="mdi-cat"
        size="48"
        color="orange"
      />
      <v-icon
        v-else
        class=""
        icon="mdi-tortoise"
        size="48"
        color="grey"
      />
    </div>
  </header>

  <!-- <main v-if="user?.email != 'danandreginakat@gmail.com'">
    <v-btn @click="login">login</v-btn>
  </main> -->

  <main class="flex flex-col w-full">
    <!-- <FirstKatComponent /> -->
    <ChoreGrid />
    <ShoppingList />
    <ToDoList />
    <!-- <p5Canvas /> -->
    <GoogleSheet />
    <HouseModel />
  </main>

</template>

<script lang="ts">
import ShoppingList from './components/ShoppingList.vue';
import p5Canvas from './components/p5Canvas.vue';
import ToDoList from './components/ToDoList.vue';
import State from './models/State';
import GoogleSheet from './components/GoogleSheet.vue';
import HouseModel from './components/HouseModel.vue';

export default {
  components: {
    GoogleSheet,
    HouseModel,
    ShoppingList,
    ToDoList,
    p5Canvas,
  },
  data() {
    return {
      includeTony: State.includeTony,
      user: null as User | null,
    }
  },
  mounted() {

    document.title = "Kat App";

    const auth = FirebaseService.GetFirebaseAuth();
    auth.onAuthStateChanged((user) => {
      this.user = user;
    })
    getRedirectResult(auth);
  },
  methods: {
    login: async function () {
      const provider = new GoogleAuthProvider();
      const auth = FirebaseService.GetFirebaseAuth();
      try {
        const userCredentials: UserCredential = await signInWithRedirect(auth, provider);
        this.user = userCredentials.user;
      } catch (error) {
        console.log('signin failed');
      }
    }
  }
}
</script>

<style scoped></style>
