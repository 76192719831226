<template>

  <SectionHeader
    icon="mdi-format-list-checks"
    title="Kat To-Do List"
  />

  <div class="flex flex-col gap-4 justify-center items-center">
    <ToDoListItem
      v-for="item in toDoItems"
      :item="item"
      :key="item.created"
      @deleteItem="onDeleteItem"
      @sortItems="sortToDoList"
      @updateItem="updateTodoItem"
    />
    <v-btn
      class="w-64 italic"
      prepend-icon="mdi-plus"
      rounded
      variant="tonal"
    >
      <ToDoItemFormModal @updatedToDoItem="addOrUpdateToDoItem" />
    </v-btn>
  </div>



</template>

<script lang="ts">
import ToDoListItem from '@/components/ToDoListItem.vue';
import SectionHeader from './SectionHeader.vue';
import { addOrUpdateToDoItem, deleteToDoItem, getAllToDoItems } from '@/services/DatabaseService';
import type { IResult } from '@/models/IResult';
import { ToDoItem } from '@/models/ToDoItem';
import ToDoItemFormModal from './ToDoItemFormModal.vue';
import { daysUntilOrSince } from '@/utils/utils';

export default {
  components: {
    ToDoListItem,
    SectionHeader,
    ToDoItemFormModal,
  },
  data() {
    return {
      /** A list of shopping items to display in this component */
      toDoItems: [] as ToDoItem[],
      showShoppingItemForm: false,
    }
  },
  methods: {
    addOrUpdateToDoItem: async function (
      newToDoItemName: string,
      newToDoItemDueDate: Date | null,
      isRecurring: boolean,
      recurAfter: number,
    ): Promise<void> {
      const newToDoItem: ToDoItem = new ToDoItem(
        Date.now(),
        newToDoItemName,
        false,
        newToDoItemDueDate,
        isRecurring ? recurAfter : 0,
      );
      await addOrUpdateToDoItem(newToDoItem);
      this.getToDoList();
    },
    getToDoList: async function (): Promise<void> {
      const result: IResult<ToDoItem[]> = await getAllToDoItems();
      if (result.success) {
        this.toDoItems = result.value!;
        this.sortToDoList();
      } else {
        console.error(result.error);
      }
    },
    onDeleteItem: async function (idOfItemToDelete: string): Promise<void> {
      await deleteToDoItem(idOfItemToDelete);
      this.getToDoList();
    },
    updateTodoItem: async function (idOfItemToUpdate: string): Promise<void> {
      const toDoItemToUpdate: ToDoItem = this.toDoItems.find((item) => item.created.toString() === idOfItemToUpdate)!;
      await addOrUpdateToDoItem(toDoItemToUpdate);

      // if a recurring item was completed, create a new one with the proper future due date
      if(toDoItemToUpdate.done && toDoItemToUpdate.recurAfter > 0) {
        const newRecurringToDoItem: ToDoItem = new ToDoItem(
          Date.now(),
          toDoItemToUpdate.name,
          false,
          new Date(Date.now() + (toDoItemToUpdate.recurAfter * 24 * 60 * 60 * 1000)), // converts ms to days
          toDoItemToUpdate.recurAfter,
        );

        await addOrUpdateToDoItem(newRecurringToDoItem);

      }

      this.getToDoList();
    },
    /** Orders the to do items based on various criteria */
    sortToDoList: function (): void {

      // group todos by various ordering qualities
      const done: ToDoItem[] = this.toDoItems.filter((td) => td.done);
      const notDone: ToDoItem[] = this.toDoItems.filter((td) => !td.done);
      const dueDates: ToDoItem[] = notDone.filter((td) => td.dueDate);
      const noDueDates: ToDoItem[] = notDone.filter((td) => !td.dueDate);
      const numOfDaysToBeConsideredFar: number = 7;
      const closeDueDates: ToDoItem[] = dueDates
        .filter((td) => daysUntilOrSince(td.dueDate!) < numOfDaysToBeConsideredFar)
        .sort((td1, td2) => td1.dueDate!.getTime() - td2.dueDate!.getTime());
      const farDueDates: ToDoItem[] = dueDates
        .filter((td) => daysUntilOrSince(td.dueDate!) >= numOfDaysToBeConsideredFar)
        .sort((td1, td2) => td1.dueDate!.getTime() - td2.dueDate!.getTime());

      // combine and return in one sorted list
      const sortedToDos: ToDoItem[] = [...closeDueDates, ...noDueDates, ...farDueDates, ...done];
      this.toDoItems = sortedToDos;

    },
  },
  mounted: async function (): Promise<void> {
    await this.getToDoList();
  },
}
</script>