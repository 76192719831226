<template>
  <v-dialog
    activator="parent"
    width="auto"
    v-model="isDialogOpen"
    @click:outside="closeDialog"
  >
    <div class="flex flex-col w-80 p-8 gap-4 rounded-xl bg-slate-200">
      <p class="mb-8 text-xl text-center">Shopping List Item</p>
      <v-text-field
        label="Name"
        variant="outlined"
        v-model="inputText"
        ></v-text-field>
      <v-btn
        :disabled="inputText.length <= 0"
        variant="tonal"
        @click="onOkButtonClick"
        >OK</v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts">

export default {
  data() {
    return {
      /** True only while this dialog is open */
      isDialogOpen: false,
      /** The current value of the text field input */
      inputText: '',
    };
  },
  methods: {
    /** Closes the dialog */
    closeDialog: function (): void {
      this.isDialogOpen = false;
    },
    /** Emits the updated shopping list item when the OK button is clicked on */
    onOkButtonClick: function(): void {
      this.$emit('updatedShoppingItem', this.inputText);
      this.closeDialog();
      this.inputText = '';
    },
  }
}
</script>