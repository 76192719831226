/** A single to do item, typically a task that dan or regina would like to
 * complete. */
export class ToDoItem {

  /** The time that this to do item was originally created, in MS since epoch.
   * Note: This is also used as the ID for a to do items */
  created: number;

  /* A human-readable name for this shopping item */
  name: string;

  /** True only if this item has been completed by someone, but has not yet been deleted */
  done: boolean;

  /** The date by which this todo item should be completed */
  dueDate: Date | null;

  /** The number of days in which this task should recur once completed. If 0,
   * this task should NOT reoccur once completed. */
  recurAfter: number;

  /**
   * Creates a new ToDoItem.
   * @param created The time when the todo item was created.
   * @param name The name of the todo item.
   * @param done Indicates if the todo item is completed.
   * @param dueDate The date by which the todo item should be completed.
   */
  constructor(created: number, name: string, done: boolean, dueDate: Date | null = null, recurAfter: number = 0) {
    this.created = created;
    this.name = name;
    this.done = done;
    this.dueDate = dueDate;
    this.recurAfter = recurAfter;
  }

}