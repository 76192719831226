<template>

  <SectionHeader icon="mdi-home" title="1212 Garfield Google Sheet" />

  <p class="italic text-sm mb-4 -mt-6 text-slate-500 text-center">
    A spreadsheet containing an in-progress list of projects and maintenance to
    do, as well things to buy, for 1212 Garfield St.
  </p>

  <!-- edit google sheet button -->
  <div class="flex justify-center">
    <v-btn prepend-icon="mdi-pencil" color="light-green" class="mb-4"
      @click="openGoogleSheetInNewTab"
    >
      Open Google Sheet
    </v-btn>
  </div>

  <!-- google sheet (in iframe) -->
  <div class="bg-[#DCEDC8]"> <!-- Matches the light green button color from Vuetify -->
    <iframe
      class="w-full h-[600px] p-4"
      src="https://docs.google.com/spreadsheets/d/1w5YU1W3aZC1e6yMC4gHJOgTzFKnUMhregoWWxBslLYQ/pubhtml?widget=true&amp;headers=false&amp;chrome=false"
    ></iframe>
  </div>
</template>

<script lang="ts">
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
  methods: {
    /** Opens the 1212 Garfield project/maintenance google sheet in a new tab */
    openGoogleSheetInNewTab: function(): void {
      const url = 'https://docs.google.com/spreadsheets/d/1w5YU1W3aZC1e6yMC4gHJOgTzFKnUMhregoWWxBslLYQ/edit#gid=0';
      window.open(url, '_blank');
    },
  }
};
</script>