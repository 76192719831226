/** A single shopping item, typically a grocery, that Dan or Regina would like
 * to buy */
export class ShoppingItem {

  /** The time that this shopping item was originally created, in MS since
   * epoch. Note: This is also used as the ID for a shopping items */
  created: number;
  
  /* A human-readable name for this shopping item */
  name: string;

  /** True only if this item has been 'got', but has not yet been deleted */
  got: boolean;

  constructor(created: number, name: string, got: boolean) {
    this.created = created;
    this.name = name;
    this.got = got;
  }
  
}