<template>

  <SectionHeader
    icon="mdi-broom"
    title="Kat Chores"
  />

  <div class="flex flex-col gap-2">

    <!-- Dates -->
    <div class="flex flex-row gap-2">
      <div class="w-48"></div> <!-- top left 'cell' of chore 'grid' -->
      <p
        class=" w-36 bg-gray-100 text-center rounded-md"
        :class="choreDateBackgroundColor(date)"
        v-for="date in dates"
      >{{ formatDate(date) }}</p>
    </div>

    <!-- row for each chore... -->
    <div
      class="flex flex-row gap-2"
      v-for="chore in chores"
    >
      <div class="flex flex-row items-center w-48">
        <v-icon
          v-if="chore.icon"
          class="mx-2 align-middle"
          color="grey-lighten-1"
          :style="{ display: 'inline-flex', alignItems: 'center' }"
          :icon="`mdi-${chore.icon}`"
        />
        <h3 class="align-middle">{{ chore.name }}</h3>
      </div>

      <ChoreCell
        v-for="date in dates"
        :choreId="chore.id"
        :date="date"
        :record="chore.getRecordForDate(date)"
        @choreUpdated="updateChores"
      />
    </div>
  </div>

</template>

<script lang="ts">
import { getDatesBeforeAndAfterToday, isToday } from "@/utils/utils";
import ChoreCell from "@/components/ChoreCell.vue";
import { getAllChores } from "@/services/DatabaseService";
import { type Chore } from "@/models/Chore";
import type { IResult } from "@/models/IResult";
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  components: {
    ChoreCell,
    SectionHeader,
  },
  data() {
    return {
      dates: getDatesBeforeAndAfterToday(3, 3),
      /** Chores to display in the chore grid */
      chores: [] as Chore[],
    }
  },
  methods: {
    /** Calculates and returns the background color css class for the date header cell of a
     * Chore Grid row */
    choreDateBackgroundColor: function (date: Date): string {
      return isToday(date)
        ? 'bg-gray-300'
        : 'bg-gray-200';
    },
    /** Formats a date how we want it to be displayed in the column headers of
     * the chore grid. */
    formatDate: function (date: Date): string {
      return new Intl.DateTimeFormat('en-US', { weekday: 'short', month: '2-digit', day: '2-digit' }).format(date);
    },
    updateChores: async function () {
      const getAllChoresResult: IResult<Chore[]> = await getAllChores();
      if (getAllChoresResult.success) {
        this.chores = getAllChoresResult.value!;
      } else {
        console.error('Failed to get chores: ' + getAllChoresResult.error);
      }
    }
  },
  mounted: async function () {
    await this.updateChores();
  },
};
</script>