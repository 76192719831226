<template>
  <v-dialog
    activator="parent"
    width="auto"
    v-model="isDialogOpen"
    @click:outside="closeDialog"

  >
    <div class="flex flex-col w-80 p-8 gap-4 rounded-xl bg-slate-200">
      <p class="mb-8 text-xl text-center">Who completed the task?</p>
      <v-btn
        outlined
        color="orange"
        @click="onDanOrReginaButtonClick(DanOrRegina.dan)"
      >Dan</v-btn>
      <v-btn
        color="green"
        @click="onDanOrReginaButtonClick(DanOrRegina.regina)"
      >Regina</v-btn>
      <v-btn
        v-if="includeTony"
        color="grey"
        @click="onDanOrReginaButtonClick(DanOrRegina.tony)"
      >Tony</v-btn>
      <v-btn
      class="mt-6"
        color="gray"
        @click="onDanOrReginaButtonClick(null)"
      >Clear</v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { DanOrRegina } from '@/enums/DanOrRegina';
import State from '@/models/State';

export default {
  data() {
    return {
      includeTony: State.includeTony,
      /** True only while this dialog is open */
      isDialogOpen: false,
      /** Enum used to represent with Dan or Regina */
      DanOrRegina,
    };
  },
  methods: {
    /** Closes the dialog */
    closeDialog: function(): void {
      this.isDialogOpen = false;
    },
    /** When a Dan or Regina button is clicked, this emits an event and passes
     * the button that was clicked on */
    onDanOrReginaButtonClick(danOrRegina: DanOrRegina | null) {
      this.$emit('danOrReginaButtonClicked', danOrRegina);
      this.closeDialog();
    },
  }
}
</script>