<template>
  <div
    class="w-36 text-center rounded-md"
    :class="choreCellBackgroundColor"
  >
    <DanOrReginaModal @danOrReginaButtonClicked="updateChoreCompleteness" />
    <p>{{ record?.completedBy }}</p>
  </div>
</template>

<script lang="ts">
import DanOrReginaModal from '@/components/DanOrReginaModal.vue';
import { DanOrRegina } from '@/enums/DanOrRegina';
import { ChoreDayRecord } from '@/models/Chore';
import type { IResult } from '@/models/IResult';
import { updateChore } from '@/services/DatabaseService';
import { formatDateYYYYMMDD, parseDateYYYYMMDD } from '@/utils/utils';
import type { PropType } from 'vue';

export default {
  components: {
    DanOrReginaModal,
  },
  props: {
    /** The ID of the chore that this cell represents */
    choreId: {
      required: true,
      type: String,
    },
    /** The date that this cell represents */
    date: {
      required: true,
      type: Date,
    },
    /** A record of if and who completed this chore on this date, if any. */
    record: {
      type: Object as PropType<ChoreDayRecord | null>,
    },
  },
  computed: {
    /** The background color of this chore cell */
    choreCellBackgroundColor: function (): string {
      let backgroundColorClass: string;
      if (this.record && this.record.completedBy != null) {
        if (this.record.completedBy == DanOrRegina.dan) {
          backgroundColorClass = 'bg-orange-200';
        } else if (this.record.completedBy == DanOrRegina.regina) {
          backgroundColorClass = 'bg-green-200';
        } else { // tony
          backgroundColorClass = 'bg-gray-400 text-white';
        }
      } else if ([0, 6].includes(this.date.getDay())) {
        backgroundColorClass = 'bg-gray-300';
      } else {
        backgroundColorClass = 'bg-gray-200';
      }
      return backgroundColorClass;
    }
  },
  methods: {
    updateChoreCompleteness: async function (personWhoCompletedChore: DanOrRegina): Promise<void> {
      const dateString: string = formatDateYYYYMMDD(this.date);
      const newRecord: ChoreDayRecord = new ChoreDayRecord(dateString, personWhoCompletedChore);
      const updateChoreResult: IResult<boolean> = await updateChore(this.choreId, newRecord);
      if (updateChoreResult.success) {
        this.$emit('choreUpdated');
      }
    },
  }
}
</script>