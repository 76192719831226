<template>

  <SectionHeader icon="mdi-home" title="House Model" />

  <p class="italic text-sm mb-4 -mt-6 text-slate-500 text-center">
    Link to the latest 3D model of our house: 
    <a class="underline text-blue-500" href="https://autode.sk/3BiFCp2">link</a>
  </p>

</template>

<script lang="ts">
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
};
</script>