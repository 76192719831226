<template>
  <SectionHeader
  icon="mdi-pencil"
    title="Kat Scratches"
  />
  <iframe
    src="https://editor.p5js.org/danno484/full/ztIHLe7YT"
    frameborder="0"
    scrolling="no"
    class="flex justify-center m-auto max-w-[400px]"
    style="width:100%; height:100vh; min-height:0; max-height:none;">
    </iframe>
  <p class=" text-center mb-4 text-xs text-red">(Need to replace with Regina's p5 sketch link)</p>
</template>

<script lang="ts">
import SectionHeader from '@/components/SectionHeader.vue';

export default {
  components: {
    SectionHeader,
  },
}
</script>