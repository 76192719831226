import { initializeApp, type FirebaseOptions, type FirebaseApp } from "firebase/app";
import { getAnalytics, type Analytics } from "firebase/analytics";
import type { Firestore } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth, type Auth } from "firebase/auth";

/** Config settings needed to initialize the Firebase app for this project */
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBl_C5Ss4-4oAlc1c4t-rxS2KXzIfevRoc",
  authDomain: "kat-app-46616.firebaseapp.com",
  projectId: "kat-app-46616",
  storageBucket: "kat-app-46616.appspot.com",
  messagingSenderId: "308154894598",
  appId: "1:308154894598:web:dd94aa2ddb713f9eaeb687",
  measurementId: "G-X907ZQ4W3T"
};

/** A (mostly static) class for getting objects from the Firebase app for this
 * project. */
export class FirebaseService {

  /** An instance of the FirebaseApp for this project */
  private static firebaseApp: FirebaseApp | null = null;

  //todo: comment
  private static firebaseAuth: Auth | null = null;

  /** The (google?) analytics for this firebase app. Not yes used. */
  private static firebaseAnalytics: Analytics | null = null;

  /** The Firestore database instance object for the project */
  private static firestore: Firestore | null = null;

  /** Gets the current Firebase App for this project. */
  public static GetFirebaseApp(): FirebaseApp {
    if (this.firebaseApp == null) {
      FirebaseService.firebaseApp = initializeApp(firebaseConfig);
    }
    return this.firebaseApp!;
  }

  /** Gets the current Firebase Authentication for this project. */
  public static GetFirebaseAuth(): Auth {
    if(this.firebaseAuth == null){
      this.firebaseAuth = getAuth(FirebaseService.GetFirebaseApp());
    }
    return this.firebaseAuth;
  }

  /** Gets the current Firebase Analytics for this project. */
  public static GetFirebaseAnalytics(): Analytics {
    if (this.firebaseApp == null) {
      this.firebaseAnalytics = getAnalytics(this.GetFirebaseApp());
    }
    return this.firebaseAnalytics!;
  }

    /** Gets the current Firestore database object for this project. */
    public static GetFirestore(): Firestore {
      if (this.firestore == null) {
        this.firestore = getFirestore(this.GetFirebaseApp());
      }
      return this.firestore!;
    }
  
}