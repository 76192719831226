<template>
  <v-dialog
    activator="parent"
    width="auto"
    v-model="isDialogOpen"
    @click:outside="closeDialog"
  >
    <div class="flex flex-col min-w-96 p-8 gap-2 rounded-xl bg-white">

      <!-- title -->
      <p class="mb-8 text-xl text-center">To Do List Item</p>

      <!-- name input  -->
      <v-text-field
        label="Name"
        variant="outlined"
        v-model="inputText"
      ></v-text-field>

      <!-- due date input -->
      <v-date-input
        label="Due Date"
        v-model="dueDate"
      ></v-date-input>

      <!-- is recurring checkbox -->
      <v-switch
        label="Recurring"
        v-model="isRecurring"
      ></v-switch>

      <!-- recurring day number input -->
      <v-number-input
        v-if="isRecurring"
        label="Days after completion"
        hint="Item will reappear this many days after being completed"
        persistent-hint
        variant="outlined"
        :min="0"
        v-model="recurAfter"
      ></v-number-input>

      <!-- ok button -->
      <v-btn
        class="mt-8"
        :disabled="inputText.length <= 0"
        variant="tonal"
        @click="onOkButtonClick"
      >OK</v-btn>

    </div>
  </v-dialog>
</template>

<script lang="ts">
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  components: {
    VDateInput,
    VNumberInput,
  },
  data() {
    return {
      /** the date that this todo item should be completed by */
      dueDate: null as Date | null,
      /** The current value of the text field input */
      inputText: '',
      /** True only while this dialog is open */
      isDialogOpen: false,
      /** If true, the 'recur after' number input will be visible */
      isRecurring: false,
      /** The number of days this to do item will recur after it's been
       * completed */
      recurAfter: 0,
    };
  },
  methods: {
    /** Closes the dialog */
    closeDialog: function (): void {
      this.isDialogOpen = false;
    },
    /** Emits the updated shopping list item when the OK button is clicked on */
    onOkButtonClick: function (): void {
      this.$emit('updatedToDoItem', this.inputText, this.dueDate, this.isRecurring, this.recurAfter);
      this.closeDialog();
      this.inputText = '';
    },
  }
}
</script>