import './assets/main.css';

// Vue imports
import { createApp } from 'vue';
import App from './App.vue';

// Vuetify imports
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from "vuetify/iconsets/mdi"
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';


// sentry import
import * as Sentry from "@sentry/vue";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

// create Vue app
const app = createApp(App);

// initialize Sentry
Sentry.init({
  app,
  dsn: "https://fd8ba3b94a6048bcdfaec9a7b84381e0@o4506791334772736.ingest.sentry.io/4506791337066496",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// mount vue app with Vuetify
app.use(vuetify).mount('#app');